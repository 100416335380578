<template>
  <div class="container">
    <TopHeader title="家属账户" returnPath="Center"></TopHeader>
    <div v-if="familyAudit" style="height: 45px;">
      <van-tabs v-model="active">
        <van-tab name="first" title="家属信息">
        </van-tab>
        <van-tab name="second" title="申请记录">
        </van-tab>
      </van-tabs>
    </div>
    <div style="flex: 1;width: 100%;overflow: auto;">
      <FamilyRecord v-if="active==='first'"></FamilyRecord>
      <FamilyApplyList v-if="active==='second'"></FamilyApplyList>
    </div>

  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import FamilyRecord from './family-record'
import FamilyApplyList from './family-apply-list'
import TopHeader from '@/components/topHeader/index.vue'
import { getUserSystemSetupAPI } from '@/api/family.js'
  export default {
  name: 'FamilyList',
  components: {
    FamilyRecord,
    FamilyApplyList,
    TopHeader
  },
    data() {
      return {
        cancel,
        list: [],
        active: 'first',
        familyAudit: false
      };
    },

    created() {
      this.getUserSystemSetupFn()
    },

    methods: {
      toHome() {
        this.$router.push({ name: 'Center' });
      },

      async getUserSystemSetupFn() {
        const organizationId = JSON.parse(sessionStorage.getItem("personInfo")).organizationId;
        const res = await getUserSystemSetupAPI(organizationId);
        if (res.code === 0 && res.data !== null) {
          if (JSON.parse(res.data.setting) !== "{}") {
            this.familyAudit = JSON.parse(res.data.setting).family_set.audit
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  & > .nav {
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
}
::v-deep .van-tab{
  font-size: 16px;
}
::v-deep .van-tabs__line{
  background: #C7000B;
  width: 60px;
}
::v-deep .van-tab--active{
color: #C7000B;
}
</style>