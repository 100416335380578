<template>
  <div class="container">
    <div style="width: 100%;display: flex;flex-direction: column;position: relative;overflow: hidden;">
      <div v-if="list.length < familyNum" style="width: 100%;height: 60px;background: #f1f1f1;">
        <div v-if="list.length < familyNum" style="width: 100%;height: 60px;position: absolute;box-sizing: border-box;padding: 10px 16px 0;background: #f1f1f1;">
          <van-button size="mini" plain style="width: 100%;height:45px;background: #FA5050;font-size: 18px;color: #fff;border-radius: 4px;" @click="handleAdd">添加家属</van-button>
        </div>
      </div>
      <div style="flex:1;overflow: auto;box-sizing: border-box;padding: 10px 16px 10px;">
        <div v-for="(item,index) in list" :key="index" style="background: #fff;margin-bottom: 15px;border-radius: 8px;">
          <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;border-bottom: 1px #f7f7f7 solid;padding-bottom: 10px;box-sizing: border-box;padding: 16px;">
              <div style="width: 55px;height: 55px;border-radius: 50%;">
              <img style="width: 100%;height: 100%;" :src="item.photo?`${apiUrl}/image?name=${item.photo}`:none" alt />
            </div>
            <div style="flex: 1;margin-left: 10px;display: flex;flex-direction: column;justify-content: space-around;height: 60px;box-sizing: border-box;padding: 0 16px;">
              <div style="display: flex;flex-direction: row;align-items: center;">
                <div style="font-size: 18px;color: #333;">{{item.name}}</div>
                <div v-if="item.feature" style="font-size: 12px;color: #fff;margin-left: 12px;background: #00B893;height: 21px;width: 42px;text-align: center;line-height: 21px;border-radius: 4px;">掌纹</div>
              </div>
              <div style="font-size: 15px;color: #666;">{{item.phone}}</div>
            </div>
          </div>
          <div style="width: 100%;text-align: right;margin-top: 15px;box-sizing: border-box;padding: 0 16px 16px;">
            <van-button size="mini" plain style="width: 80px;height:33px;background: #fff;font-size: 16px;color: #FA5050;border-color: #FA5050;margin-right: 12px;box-sizing: border-box;border-radius: 4px;" @click="handleEdit(item)">编辑</van-button>
            <van-button size="mini" plain style="width: 80px;height:33px;background: #FA5050;font-size: 16px;color: #fff;border-color: #FA5050;box-sizing: border-box;border-radius: 4px;" @click="handleDelete(item)">删除</van-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import none from "@/assets/photo_none.png";
import store from '../../store'
import { Dialog, Toast } from 'vant';
import { getUserMemberList, removeUserMember, getUserSystemSetupAPI } from '@/api/family.js'
  export default {
  name: 'FamilyRecord',
    data() {
      return {
        cancel,
        none,
        apiUrl: process.env.VUE_APP_BASE_API,
        list: [],
        familyNum: null 
      };
  },

    created() {
      this.getList()
      this.getUserSystemSetupFn()
    },

    methods: {
      toHome() {
        this.$router.push({ name: 'Center' });
      },

      async getUserSystemSetupFn() {
        const organizationId = JSON.parse(sessionStorage.getItem("personInfo")).organizationId;
        const user = JSON.parse(sessionStorage.getItem("personInfo"));
        const res = await getUserSystemSetupAPI(organizationId);
        if (res.code === 0 && res.data !== null) {
          if (JSON.parse(res.data.setting) !== "{}") {
            this.familyNum = JSON.parse(res.data.setting).family_set.num
          }
        }
      },

      async getList() {
        // featureFlag: 0 表示未上传， 1 表示已上传
        const userId = store.getters.userId;
        const res = await getUserMemberList(userId)
        if (res.code === 0) {
          this.list = res.data
        }
      },

      handleEdit(item) {
        this.$router.push({ name:'FamilyDetail', query: { id: item.id }})
      },

      handleDelete(item) {
        Dialog.confirm({
          title: "删除",
          message: "确定要删除该家属吗?",
        })
          .then(async () => {
            const res = await removeUserMember(item.id);
            if (res.code === 0) {
              Toast.success({ message: "删除成功", });
              this.getList()
            }
          })
          .catch(() => {
            return;
          });
      },

      handleAdd() {
        this.$router.push({ name:'FamilyDetail' })
      }
    }
  }
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>