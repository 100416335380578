<template>
  <div class="container">
    <div style="flex: 1;width: 100%;box-sizing: border-box;padding: 10px 16px;overflow: auto;">
      <div v-for="(item,index) in list" :key="index" style="background: #fff;margin-bottom: 15px;border-radius: 8px;">
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 16px;height:50px">
          <div style="color: #333;font-weight: bold;">姓名</div>
          <div style="display: flex;flex-direction: row;align-items: center;vertical-align: bottom;">
            <span style="color: #333;font-weight: bold;">{{item.name}}</span>
            <img @click="handleDetail(item)" style="height: 24px;height: 24px;margin-left: 8px;" src="@/assets/images/rightImg.png" alt="">
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 16px;height:44px">
          <div style="color: #333;">手机号</div>
          <div style="color: #666;">{{item.phone}}</div>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 16px;height:44px">
          <div style="color: #333;">状态</div>
          <div>
            <van-tag v-if="item.status === 0" color="#FFA900" size="medium">审核中</van-tag>
            <van-tag v-if="item.status === 1" color="#00B893" size="medium">同意</van-tag>
            <van-tag v-if="item.status === 2" color="#ED3944" size="medium">拒绝</van-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import none from "@/assets/photo_none.png";
import store from '../../store'
import { Dialog, Toast } from 'vant';
import { getUserMemberVerifyList, removeUserMember } from '@/api/family.js'
import CommonBorder from '@/components/commonBorder/index.vue'
  export default {
  name: 'FamilyApplyList',
  components:{ CommonBorder },
    data() {
      return {
        cancel,
        none,
        apiUrl: process.env.VUE_APP_BASE_API,
        list: [],
      };
    },

    created() {
      this.getList()
    },

    // watch: {
    //   '$route': function(val) {
    //     this.getList()
    //   }
    // },

    methods: {

      async getList() {
        const userId = store.getters.userId;
        const res = await getUserMemberVerifyList(userId)
        if (res.code === 0) {
          this.list = res.data
        }
      },

      handleDetail(item) {
        this.$router.push({ name:'FamilyApplyDetail', query: { item: item }})
      },
    }
  }
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>